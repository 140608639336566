import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { bool, string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Textarea,
  Flex,
  Spacer,
  // hooks
  useToast,
  Text,
  Image,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Wishlist from './WishlishCard';
import useGuestInfo from '@hooks/useGuestInfo';

import txtWording from './locales';
import { API_HOSTNAME, API_TABLE_NAME, THE_BRIDE } from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS, NAVIGATION_COLOR } from '@/constants/colors';
import { LOADING_STATE, INPUT_COMMON_PROPS, ERROR_TYPE } from './types';
import { ASSETS_FLOWER_WEDDING } from '@/constants/assets';

const enc = (string) => encodeURIComponent(string);

function WishesSection({ lang }) {
  const [loadingType, setLoadingType] = useState(LOADING_STATE.IDLE);
  const [wishlist, setWishlist] = useState([]);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const { name: nameFromUrl } = useGuestInfo();

  const [name, setName] = useState(nameFromUrl);
  const [ucapan, setUcapan] = useState(`Dear ${THE_BRIDE}...`);
  const calledOne = useRef(false);
  const toast = useToast();

  const handleSetState = (e, setState) => {
    const value = e.target.value;
    setErrorType(ERROR_TYPE);
    setState(value);
  };

  const finishLoading = () => {
    setLoadingType(LOADING_STATE.IDLE);
  };

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
        isClosable: true,
      });
    }
  };

  /**
   * function to get wishlist data
   * @return {void}
   */
  const getData = async () => {
    setLoadingType(LOADING_STATE.GET_DATA);

    try {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'GET',
      };

      const rawResult = await fetch(
        `${API_HOSTNAME}?action=read&tableName=${API_TABLE_NAME}`,
        options,
      );
      const response = await rawResult.json();

      if (response.success) {
        setWishlist(response.data || []);
      } else {
        console.error('ERR_WHEN_GET_DATA', 200);
      }
      finishLoading();
    } catch (e) {
      finishLoading();
      console.error('ERR_WHEN_CALL_DATA', 500);
    }

    calledOne.current = true;
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validate input data
    if (!name || !ucapan) {
      setErrorType({
        name: !name && txtWording.requiredField[lang],
        ucapan: !ucapan && txtWording.requiredField[lang],
      });
      return;
    }

    setLoadingType(LOADING_STATE.SUBMIT_DATA);

    try {
      const time = dayjs(new Date()).format('DD MMM YYYY | hh:mm:ss A');
      const config = `tableName=${API_TABLE_NAME}&action=insert_wish`;
      const query = `?${config}&nama=${enc(name)}&wish=${enc(ucapan)}&time=${enc(time)}`;

      const rawResult = await fetch(API_HOSTNAME + query, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      const response = await rawResult.json();
      if (response.success) {
        setName('');
        setUcapan('');
        getData();
        handleSetAlert(true);
      } else {
        handleSetAlert(false);
      }
    } catch (e) {
      handleSetAlert(false);
    }

    finishLoading();
  };

  /** Side effect to autoscroll */
  useEffect(() => {
    if (!calledOne.current) {
      getData();
    }
  }, []);

  return (
    <Box bgColor="bgSecondary" pos="relative">
      <Box padding="42px 24px">
        {/* Title & Description sections */}
        <WithAnimation>
          <Text textAlign="center" color="mainColorText" fontSize="md" marginBottom="8px">
            Please leave your sincere prayer and wishes to:
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            color="mainColorText"
            textAlign="center"
            fontWeight="normal"
            size="xl"
            fontFamily="CustomFont"
            marginBottom="16px"
            // textTransform="uppercase"
          >
            {THE_BRIDE}
          </Heading>
        </WithAnimation>
        <WithAnimation left>
          <Image
            className="animation__flower"
            src={ASSETS_FLOWER_WEDDING}
            pos="absolute"
            h="120px"
            top="-24px"
            left="8px"
          />
        </WithAnimation>
        <Box padding="24px 24px 42px 24px" borderBottomRadius="16px" bgColor="bgSecondary">
          {/* Box for FORM */}
          <WithAnimation>
            <Box
              padding="16px 0"
              bgColor="bgPrimary"
              border="1px solid"
              borderColor={NAVIGATION_COLOR}
              borderRadius="16px"
            >
              <FormControl padding="0 16px" margin="0" isInvalid={errorType.name}>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder={txtWording.name[lang]}
                  onChange={(e) => handleSetState(e, setName)}
                  value={name}
                />
                <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
              </FormControl>
              <Box margin="8px 0" height="1px" width="100%" bgColor={NAVIGATION_COLOR} />
              <FormControl padding="0 16px" margin="0" isInvalid={errorType.ucapan}>
                <Textarea
                  {...INPUT_COMMON_PROPS}
                  placeholder={txtWording.wish[lang]}
                  onChange={(e) => handleSetState(e, setUcapan)}
                  value={ucapan}
                />
                <FormErrorMessage marginTop="4px">{errorType.ucapan}</FormErrorMessage>
              </FormControl>
              <Flex justifyItems="end" padding="0 16px 0 0">
                <Spacer />
                <Button
                  isLoading={loadingType === LOADING_STATE.SUBMIT_DATA}
                  padding="8px 24px"
                  textTransform="uppercase"
                  letterSpacing="2px"
                  onClick={handleSubmit}
                  {...BUTTON_PROPS}
                  {...BUTTON_PADDING_PROPS}
                >
                  {txtWording.send[lang]}
                </Button>
              </Flex>
            </Box>
          </WithAnimation>
          {/* Wishlist Card */}
          <WithAnimation>
            <Wishlist wishlistData={wishlist} loading={loadingType === LOADING_STATE.GET_DATA} />
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
