import get from '@invitato/helpers/dist/getLocale';

export default {
  thanks: get('Terima Kasih', 'Thank You!'),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Sampai jumpa di hari bahagia kami!', 
    'It will be a joy for us if you are able to be attend on our wedding day. Thank you for all the kind words, prayers, and attentions given. We wish your understanding for all health protocols restrictions. See you on our wedding day!'
  ),
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.', 
    `It will be a joy for us if you are still willing to give your blessing from afar. Please know that you are in our thoughts and will be in our hearts, even though we are deeply sorry, we can't invite you to the ceremony due to the pandemic restriction. Thank you for all the words, prayers, and attention given.`
  ),
};