import React from 'react';
import { Box, Center, Text, Link, Image, Divider } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

import LazyImage from '@components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';

import { ENABLE_COLLABORATION, ENABLE_MUSLIM_LANGUAGE, SELECTED_VENDOR_COLLABORATION } from '@/constants/feature-flags';
import {
  ASSETS_ANIMAL,
  BG_COVER,
  IMG_LOGO,
  LOGO_INVITATO,
} from '@/constants/assets';
import { BRIDE_HASHTAG, INVITATO_URL, SOUND_BY, SOUND_URL, THE_BRIDE } from '@/constants';

import { FOOTER_LINK_PROPS } from './types';
import txt from './locales';

function FooterSection() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box
      bgColor="bgPrimary"
      bgImage={`url(${BG_COVER})`}
      bgSize="cover"
      width="100%"
      pos="relative"
    >
      <Box
        bg="transparent"
        bgImage="linear-gradient(180deg, #d6ddce00 0%, #d6ddce 100%)"
        transition="background 0.3s, border-radius 0.3s, opacity 0.3s"
        opacity="1"
      >
        <Box textAlign="center" color="mainColorText" width="100%" position="relative">
          <Box padding="62px 16px">
            <WithAnimation>
              <Text margin="0 0 24px 0" fontWeight="lg" fontStyle="italic">
                Sincere love,
              </Text>
            </WithAnimation>
            <Center>
              <WithAnimation>
                <LazyImage maxHeight="120px" src={IMG_LOGO} />
              </WithAnimation>
            </Center>
            <WithAnimation>
              <Text margin="24px 0 24px 0">
                {isInvitation ? txt.closingInvitation[lang] : txt.closingAnnouncement[lang]}
                {ENABLE_MUSLIM_LANGUAGE && (
                  <>
                    <br /> <br />
                    Wassalamualaikum Warahmatullahi Wabarakatuh.
                  </>
                )}
              </Text>
            </WithAnimation>
            <WithAnimation>
              <Text fontSize="lg" fontWeight="bold" letterSpacing="1px" margin="24px 0 24px 0">
                {BRIDE_HASHTAG}
              </Text>
            </WithAnimation>
            <WithAnimation>
              <Image src={ASSETS_ANIMAL} height="220px" margin="auto" />
            </WithAnimation>
          </Box>
        </Box>
      </Box>
      {/* INVITATO SECTION */}
      <Box bgColor="#D6DDCE" padding="24px" textAlign="center">
        {/* INVITATO SECTION */}
        <Box>
          <WithAnimation>
            <Center>
              <Link href={INVITATO_URL} target="_blank">
                <LazyImage src={LOGO_INVITATO} filter="invert(1)" maxWidth="140px" margin="0" />
              </Link>
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Text textAlign="center" color="mainColorText" fontSize="sm" margin="0 0 12px 0">
              Created with love by Invitato
            </Text>
          </WithAnimation>
          {ENABLE_COLLABORATION && (
            <Box margin="-5px 0 16px">
              <Center>
                <Divider
                  margin="4px 0 12px"
                  maxWidth="60px"
                  opacity="1"
                  height="1px"
                  bgColor="mainColorText"
                  border="none"
                  borderBottom="none"
                />
              </Center>
              <Center>
                <Link href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`} target="_blank">
                <Image
                      src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                      {...SELECTED_VENDOR_COLLABORATION.imgProps}
                    />
                </Link>
              </Center>
              <Center marginTop="2px">
                <Text color="mainColorText" align="center" fontSize="xs">
                In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                </Text>
              </Center>
            </Box>
          )}
          <Text {...FOOTER_LINK_PROPS}>
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Right Reserved`}
          </Text>
          <Link {...FOOTER_LINK_PROPS} href={SOUND_URL}>
            <Text>{`Song by ${SOUND_BY}`}</Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
