import React from 'react';
import { string, object, arrayOf } from 'prop-types';
import {
  Box,
  Text,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Accordion,
} from '@chakra-ui/react';

import useInvitation from '@hooks/useInvitation';
import LazyImage from '@components/Common/LazyImage';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';

import txt from './locales';
import WithAnimation from '@components/Common/WithAnimation';

function WeddingDetail({ items, lang }) {
  const invitation = useInvitation();

  return (
    <Accordion defaultIndex={invitation ? [1] : [0]} allowMultiple>
      {items
        .filter((x) => x.show)
        .map((item) => {
          const {
            date,
            dresscode,
            dresscodeUrl,
            imgUrl,
            info,
            location,
            locationRoad,
            locationUrl,
            time,
            title,
            hideIcon,
          } = item;

          return (
            <AccordionItem key={title} border="none">
              <AccordionButton {...BUTTON_PROPS} padding="0">
                <Box
                  padding="8px"
                  flex="1"
                  textAlign="left"
                  borderLeft="2px solid"
                  borderLeftColor="mainColorText"
                  color="mainColorText"
                >
                  <WithAnimation>
                    <Text
                      fontSize="sm"
                      fontFamily="bodyAlternative"
                      textTransform="uppercase"
                      letterSpacing="2px"
                    >
                      {txt.subtitle[lang]}
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <Text marginTop="4px" fontSize="lg">
                      {title}
                    </Text>
                  </WithAnimation>
                </Box>

                {!hideIcon && <AccordionIcon />}
              </AccordionButton>
              <AccordionPanel pb={0} padding="12px 0">
                <Box>
                  {imgUrl && (
                    <LazyImage
                      margin="8px 0"
                      border="2px solid"
                      borderColor="mainColorText"
                      src={imgUrl}
                      height="200px"
                      width="200px"
                      objectFit="cover"
                      objectPosition="center"
                    />
                  )}
                  <WithAnimation>
                    <Text
                      fontSize="md"
                      color="mainColorText"
                      dangerouslySetInnerHTML={{ __html: `${txt.date[lang]}: ${date}` }}
                    />
                  </WithAnimation>
                  <WithAnimation>
                    <Text
                      fontSize="md"
                      color="mainColorText"
                      dangerouslySetInnerHTML={{ __html: `${txt.time[lang]}: ${time}` }}
                    />
                  </WithAnimation>

                  {/* Dresscode image & Text */}
                  {dresscode && (
                    <Text fontSize="md" color="mainColorText">
                      Dresscode: {dresscode}
                    </Text>
                  )}
                  {dresscodeUrl && <LazyImage borderRadius="8px" width="80%" src={dresscodeUrl} />}
                  {info && (
                    <Text
                      fontSize="md"
                      fontStyle="italic"
                      color="mainColorText"
                      dangerouslySetInnerHTML={{ __html: info }}
                    />
                  )}
                  {invitation && location && (
                    <WithAnimation>
                      <Text fontSize="md" color="mainColorText">
                        {txt.place[lang]}: {location}
                      </Text>
                    </WithAnimation>
                  )}
                  {invitation && locationRoad && (
                    <WithAnimation>
                      <Text fontSize="md" color="mainColorText">
                        <span dangerouslySetInnerHTML={{ __html: locationRoad }} />
                      </Text>
                    </WithAnimation>
                  )}
                  {/* Button Action */}
                  {locationUrl && (
                    <WithAnimation>
                      <ViewLocationButton
                        marginTop="16px"
                        text={txt.seeLocation[lang]}
                        href={locationUrl}
                        {...BUTTON_PADDING_PROPS}
                        size="sm"
                      />
                    </WithAnimation>
                  )}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
    </Accordion>
  );
}

WeddingDetail.propTypes = {
  items: arrayOf({
    date: string.isRequired,
    info: string,
    dresscode: string,
    time: string.isRequired,
    title: string.isRequired,
    location: string,
    locationRoad: string,
    titleProps: object,
  }),
  lang: string,
};

WeddingDetail.defaultProps = {
  items: [],
  lang: 'id',
};

export default React.memo(WeddingDetail);
