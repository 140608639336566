import React from 'react';
import { string } from 'prop-types';
import { Box, Heading, Image, Text } from '@chakra-ui/react';
import loadable from '@loadable/component';

// import useLang from '@hooks/useLang';
// import ImageHover from '@components/Common/ImageHover';
import WithAnimation from '@/components/Common/WithAnimation';
import ImgDivider from '@/assets/divider.svg';

import { THE_BRIDE } from '@/constants';
// import { BUTTON_PADDING_PROPS, BUTTON_PROPS, NAVIGATION_COLOR } from '@/constants/colors';
import { ASSETS_FLOWER_WEDDING, BG_COVER } from '@/constants/assets';

import { photos as photosLive } from './api/photos-2';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

// import Content from './Content';

function PhotoSection() {
  // const { isOpen, onClose, onOpen } = useDisclosure();
  // const lang = useLang();

  return (
    <Box padding="42px 0" bgSize="cover" bgColor="bgSecondary" pos="relative">
      <Image
        src={ASSETS_FLOWER_WEDDING}
        className="animation__flower-secondary"
        position="absolute"
        right="0"
        height="180px"
        zIndex="10"
      />
      <Box padding="32px">
        <LoadableGallerry data={photosLive} />
      </Box>
      {/* <ImageHover
        images={[IMG_GALLERY_1]}
        imageProps={{
          height: '240px',
          width: '240px',
          margin: '8px auto',
          position: 'relative',
          zIndex: '10',
          border: '1px solid',
          borderColor: NAVIGATION_COLOR,
        }}
      /> */}
      <Box
        marginTop="-190px"
        height="230px"
        bgImage={`url(${BG_COVER})`}
        bgSize="cover"
        bgPos="center"
      >
        <Box
          height="100%"
          bg="transparent"
          bgImage="linear-gradient(180deg, #eee3d700 0%, #e5e3cf 100%)"
          transition="background 0.3s, border-radius 0.3s, opacity 0.3s"
          opacity="1"
        />
      </Box>
      <Box padding="0 32px">
        <WithAnimation>
          <Heading
            margin="-20px 0 20px 0"
            fontWeight="normal"
            color="mainColorText"
            textAlign="center"
            size="xl"
            fontFamily="CustomFont"
            //textTransform="uppercase"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text margin="8px 0" textAlign="center" color="mainColorText" fontSize="md">
            {`“Barang siapa yang mencintai karena Allah, membenci karena Allah, memberi karena Allah dan tidak memberi karena Allah, maka sungguh telah sempurna imannya.”`}
            <br />
            (HR. Abu Dawud dan At-Tirmidzi)
          </Text>
        </WithAnimation>
        {/* Button Section */}
        {/* <WithAnimation>
          <Center>
            <Button
              rightIcon={<TbHandClick size="20px" style={{ marginLeft: '-4px' }} />}
              size="sm"
              margin="16px 0 32px 0"
              letterSpacing="1px"
              onClick={() => onOpen()}
              {...BUTTON_PROPS}
              {...BUTTON_PADDING_PROPS}
            >
              {txt.button[lang]}
            </Button>
          </Center>
        </WithAnimation> */}
        <WithAnimation>
          <Image src={ImgDivider} margin="auto" maxW="300px" />
        </WithAnimation>
      </Box>
      {/* Content gallery
      <Content lang={lang} isOpen={isOpen} onClose={onClose} /> */}
    </Box>
  );
}

PhotoSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(PhotoSection);
